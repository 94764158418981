<template>
  <div class="position-relative" style="min-height: 300px">
    <div v-if="!loading" class="d-flex justify-content-between">
      <le-day-availability @update="(value) => updateAvailability('MONDAY', value)" weekday="Monday" :type="type" :day-times="formattedAvailability.monday"/>
      <le-day-availability @update="(value) => updateAvailability('TUESDAY', value)" weekday="Tuesday" :type="type" :day-times="formattedAvailability.tuesday"/>
      <le-day-availability @update="(value) => updateAvailability('WEDNESDAY', value)" weekday="Wednesday" :type="type" :day-times="formattedAvailability.wednesday"/>
      <le-day-availability @update="(value) => updateAvailability('THURSDAY', value)" weekday="Thursday" :type="type" :day-times="formattedAvailability.thursday"/>
      <le-day-availability @update="(value) => updateAvailability('FRIDAY', value)" weekday="Friday" :type="type" :day-times="formattedAvailability.friday"/>
      <le-day-availability @update="(value) => updateAvailability('SATURDAY', value)" weekday="Saturday" :type="type" :day-times="formattedAvailability.saturday"/>
      <le-day-availability @update="(value) => updateAvailability('SUNDAY', value)" weekday="Sunday" :type="type" :day-times="formattedAvailability.sunday"/>
    </div>
    <le-loading v-if="loading"></le-loading>
  </div>
</template>

<script>
import AvailabilityService from '@/services/availability.service'
import LeLoading from '@/components/ntm/LeLoading.vue'

export default {
  components: { LeLoading },
  props: {
    type: {
      type: String,
      default: 'CHOOSE'
    },
    entityType: {
      type: String,
      required: true
    },
    entityUuid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      availability: []
    }
  },
  mounted () {
    this.fetchAvailability()
  },
  computed: {
    formattedAvailability () {
      return {
        monday: this.availability.filter((a) => a.day === 'MONDAY'),
        tuesday: this.availability.filter((a) => a.day === 'TUESDAY'),
        wednesday: this.availability.filter((a) => a.day === 'WEDNESDAY'),
        thursday: this.availability.filter((a) => a.day === 'THURSDAY'),
        friday: this.availability.filter((a) => a.day === 'FRIDAY'),
        saturday: this.availability.filter((a) => a.day === 'SATURDAY'),
        sunday: this.availability.filter((a) => a.day === 'SUNDAY')
      }
    }
  },
  methods: {
    updateAvailability (day, value) {
      AvailabilityService.upsert({
        day: day,
        entityType: this.entityType,
        entityUuid: this.entityUuid,
        times: value
      })
    },
    fetchAvailability () {
      AvailabilityService.show(this.entityType, this.entityUuid).then((response) => {
        this.availability = response.data
        setTimeout(() => {
          this.loading = false
        })
      })
    }
  }
}
</script>

import ApiService from './api.service'

const ConnectedAccountService = {
  index () {
    return ApiService.get('api/integrations/connected-accounts')
  },

  getYoutubeAuthUrl () {
    return ApiService.get('api/integrations/youtube/auth-url')
  },

  disconnectAccount (id) {
    return ApiService.deleteWithoutLoading(`api/integrations/connected-accounts/${id}`)
  }
}

export default ConnectedAccountService

<template>
  <div>
    <div class="weekday-column">
      <h4>{{ weekday }}</h4>
      <div class="availability-selections">
        <div v-for="(selection, i) in selections" class="d-flex mb-2 rounded p-1 border" :class="{'border-info': selection.type === 'AVAILABLE', 'border-warning': selection.type === 'UNAVAILABLE'}" :key="i">
          <div class="d-flex flex-column mr-2">
            <span>{{ formatTime(selection.startTimeInMinutes) }} </span> <span> {{ formatTime(selection.endTimeInMinutes) }}</span>
          </div>
          <div class="d-flex flex-column">
            <button class="btn btn-sm btn-danger">
              <i class="fa fa-trash" @click="deleteTime(i)"></i>
            </button>
          </div>
        </div>
      </div>
      <button v-if="!isOpenedAddForm" @click="isOpenedAddForm = true" type="submit" class="btn btn-primary w-100"><i class="fa fa-plus"></i></button>
      <form v-if="isOpenedAddForm" @submit.prevent="addAvailability" class="my-3">
        <div class="form-group">
          <input type="time" step="900" :list="`startTimeOptions-${componentId}`" id="start" name="start" v-model="formSelection.startStr">
        </div>
        <div class="form-group">
          <input type="time" step="900" :list="`endTimeOptions-${componentId}`" id="end" name="end" v-model="formSelection.endStr">
        </div>
        <datalist :id="`startTimeOptions-${componentId}`">
          <option v-for="time in startTimeOptions" :key="time" :value="time" />
        </datalist>
        <datalist :id="`endTimeOptions-${componentId}`">
          <option v-for="time in endTimeOptions" :key="time" :value="time" />
        </datalist>
        <select v-if="type === 'CHOOSE'" v-model="formSelection.type" class="form-control form-control-sm mb-2">
          <option value="AVAILABLE">AVAILABLE</option>
          <option value="UNAVAILABLE">UNAVAILABLE</option>
        </select>
        <button :disabled="isInvalidTimeRange" type="submit" class="btn btn-primary w-100"><i class="fa fa-floppy-o"></i></button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'CHOOSE'
    },
    weekday: {
      type: String,
      required: true
    },
    dayTimes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isOpenedAddForm: false,
      formSelection: {
        type: 'AVAILABLE',
        startStr: '',
        endStr: ''
      },
      timeOptions: [],
      selections: []
    }
  },
  computed: {
    componentId () {
      return this._uid
    },
    endTimeOptions () {
      return this.formSelection.startStr ? this.timeOptions.filter(time => time > this.formSelection.startStr) : this.timeOptions
    },
    startTimeOptions () {
      return this.formSelection.endStr ? this.timeOptions.filter(time => time < this.formSelection.endStr) : this.timeOptions
    },
    isInvalidTimeRange () {
      if (!this.formSelection.startStr || !this.formSelection.endStr || (this.type === 'CHOOSE' ? !this.formSelection.type : false)) return true
      const startMinutes = this.convertToMinutes(this.formSelection.startStr)
      const endMinutes = this.convertToMinutes(this.formSelection.endStr)
      return startMinutes >= endMinutes
    }
  },
  mounted () {
    this.generateTimeOptions()
    this.selections = this.dayTimes
  },
  methods: {
    generateTimeOptions () {
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          const formattedTime = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:00`
          this.timeOptions.push(formattedTime)
        }
      }
    },
    addAvailability () {
      const startMinutes = this.convertToMinutes(this.formSelection.startStr)
      const endMinutes = this.convertToMinutes(this.formSelection.endStr)

      if (startMinutes >= endMinutes) {
        alert('Start time must be earlier than the end time.')
        return
      }

      // Find conflicting intervals and merge them
      let minStart = startMinutes
      let maxEnd = endMinutes
      const nonConflictingSelections = []

      for (const selection of this.selections) {
        if (
          (startMinutes >= selection.startTimeInMinutes && startMinutes < selection.endTimeInMinutes) ||
          (endMinutes > selection.startTimeInMinutes && endMinutes <= selection.endTimeInMinutes) ||
          (startMinutes <= selection.startTimeInMinutes && endMinutes >= selection.endTimeInMinutes)
        ) {
          // Expand the range to merge
          minStart = Math.min(minStart, selection.startTimeInMinutes)
          maxEnd = Math.max(maxEnd, selection.endTimeInMinutes)
        } else {
          // Keep non-conflicting selections
          nonConflictingSelections.push(selection)
        }
      }

      // Add the merged interval to the updated list
      nonConflictingSelections.push({ startTimeInMinutes: minStart, endTimeInMinutes: maxEnd, type: this.type === 'CHOOSE' ? this.formSelection.type : this.type })
      this.selections = nonConflictingSelections
      this.selections.sort((a, b) => a.startTimeInMinutes - b.startTimeInMinutes)
      this.$emit('update', this.selections)

      // Reset form inputs
      this.formSelection.startStr = ''
      this.formSelection.endStr = ''
    },

    isOverlap (newStart, newEnd) {
      for (const selection of this.selections) {
        if (
          (newStart >= selection.startTimeInMinutes && newStart < selection.endTimeInMinutes) ||
          (newEnd > selection.startTimeInMinutes && newEnd <= selection.endTimeInMinutes) ||
          (newStart <= selection.startTimeInMinutes && newEnd >= selection.endTimeInMinutes)
        ) {
          return true
        }
      }
      return false
    },
    convertToMinutes (timeStr) {
      if (!timeStr) return 0
      const [hours, minutes] = timeStr.split(':').map(Number)
      return hours * 60 + minutes
    },
    formatTime (minutes) {
      const hours = Math.floor(minutes / 60)
      const mins = minutes % 60
      return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`
    },
    deleteTime (index) {
      this.selections.splice(index, 1)
      this.$emit('update', this.selections)
    }
  }
}
</script>

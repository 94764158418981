import ApiService from './api.service'

const AvailabilityService = {
  upsert (data) {
    return ApiService.postWithoutLoading('api/availability', data)
  },
  show (entity, uuid) {
    return ApiService.get(`api/availability/${entity}/${uuid}`)
  }
}

export default AvailabilityService

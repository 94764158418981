const fields = [
  {
    key: 'league',
    type: 'ntm-select',
    optionsApi: 'leagues',
    templateOptions: {
      label: 'league',
      placeholder: 'Select league'
    }
  },
  {
    key: 'venues',
    type: 'ntm-select',
    multiple: true,
    optionsApi: 'venues',
    templateOptions: {
      label: 'venues'
    }
  },
  {
    key: 'extendsSchedules',
    type: 'ntm-select',
    multiple: true,
    options: [],
    templateOptions: {
      label: 'extendsLeagues'
    }
  },
  {
    key: 'suffix',
    type: 'input',
    templateOptions: {
      label: 'suffix'
    }
  },
  {
    key: 'periodTime',
    type: 'input',
    templateOptions: {
      inputType: 'number',
      label: 'periodTime'
    }
  },
  {
    key: 'minTeamPlayers',
    type: 'input',
    templateOptions: {
      inputType: 'number',
      label: 'minTeamPlayers'
    }
  },
  {
    key: 'maxTeamPlayers',
    type: 'input',
    templateOptions: {
      inputType: 'number',
      label: 'maxTeamPlayers'
    }
  },
  {
    key: 'sponsor',
    type: 'ntm-select',
    optionsApi: 'sponsors',
    templateOptions: {
      label: 'sponsor'
    }
  },
  {
    key: 'hideLeagueRanking',
    type: 'boolean',
    templateOptions: {
      label: 'hideLeagueRanking'
    }
  },
  {
    key: 'isTournament',
    type: 'boolean',
    templateOptions: {
      label: 'isTournament'
    }
  },
  {
    key: 'showOnLandingPage',
    type: 'boolean',
    templateOptions: {
      label: 'showOnLandingPage'
    }
  },
  {
    key: 'hasAutoSyncGameYoutubeEvents',
    type: 'boolean',
    templateOptions: {
      label: 'hasAutoSyncGameYoutubeEvents'
    }
  }
]

export default fields

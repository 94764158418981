<template>
  <div class="about">
    <page-header :title="$tc('connectedAccount.name', 2)">
    </page-header>
    <page-content>
      <div class="row">
        <div class="offset-sm-3 col-sm-6 col-12">
          <section class="p-4 border border-primary w-auto">
            <h2>YouTube</h2>
            <div v-if="youtubeError" class="alert alert-danger">{{$t(youtubeError)}}</div>
            <div v-if="model.YOUTUBE">
              <div class="d-flex" style="gap: 10px">
                <h3 class="m-0">{{ model.YOUTUBE.userName }}</h3>

                <button @click="disconnectAccount(model.YOUTUBE)" class="btn btn-danger"><i class="fa fa-close"></i></button></div>
            </div>
            <div v-if="!model.YOUTUBE">
              <button  @click="connectToYoutube()" class="btn btn-info">Connect</button>
            </div>
          </section>
        </div>
      </div>
    </page-content>
  </div>
</template>

<script>
import fields from '../settings/formDefinition'
import ConnectedAccountService from '@/services/connectedAccount.service'
import { useRouteQuery } from '@/use/useRouteQuery'

export default {
  data () {
    return {
      youtubeError: null,
      model: {},
      form: {},
      fields: fields
    }
  },
  setup () {
    const error = useRouteQuery('error')

    return { error }
  },
  mounted () {
    if (this.error) {
      this.$notify.error(this.$t(this.error))
    }

    if (this.error.includes('youtube.')) {
      this.youtubeError = this.error
    }
  },
  beforeRouteEnter (to, from, next) {
    ConnectedAccountService.index().then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  methods: {
    setData (data) {
      this.model = data
    },
    fetchData () {
      ConnectedAccountService.index().then((response) => {
        this.setData(response.data)
      })
    },
    connectToYoutube () {
      ConnectedAccountService.getYoutubeAuthUrl().then((response) => {
        window.location = response.data.authUrl
      })
    },
    disconnectAccount (account) {
      ConnectedAccountService.disconnectAccount(account.id).then(() => {
        this.fetchData()
      })
    }
  }
}
</script>

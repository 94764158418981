<template>
  <div class="btn-group">
    <button class="btn btn-info btn-sm"
            :disabled="game.status === 'LIVE'"
            v-if="['NOT_PLAYED', 'SCHEDULED', 'FINISHED'].includes(game.status)"
            @click="scheduleGameModal(game)"><i class="fa fa-clock-o"></i></button>
    <button class="btn btn-warning btn-sm"
            :disabled="game.status === 'LIVE'"
            v-if="['SCHEDULED'].includes(game.status)"
            @click="cancelScheduledGame(game)"><i class="fa fa-times"></i></button>
    <modal name="schedule-modal" :adaptive="true" height="auto">
      <form @submit.prevent="scheduleGameSubmit()">
        <ntm-block :title="scheduleGameTitle">
          <formly-form :form="scheduleGameForm" :model="scheduleGameModel" :fields="scheduleGameFields"></formly-form>
          <button class="btn btn-success push-15" :disabled="scheduleGameLoading"><span
            v-if="!scheduleGameLoading">{{ $t('save') }}</span><i v-if="scheduleGameLoading"
                                                                  class="fa fa-cog fa-spin"></i></button>
        </ntm-block>
      </form>
    </modal>
  </div>

</template>

<script>
import GameService from '@/services/game.service'
import { useTimeline } from '@/use/useTimeline'
import { useGameSwap } from '@/use/useGameSwap'
import scheduleFormDefinition from '@/views/schedules/games/formDefinitions/scheduleFormDefinition'

export default {
  props: {
    game: Object,
    venues: Array
  },
  data () {
    return {
      loading: false,
      scheduleGameForm: {},
      scheduleGameFields: scheduleFormDefinition,
      scheduleGameModel: {
        time: '',
        venue: null,
        info: ''
      },
      scheduleGameLoading: false
    }
  },
  setup () {
    const { fetchCurrentTimeline } = useTimeline()
    const { swapGame, swapGameTimeAndVenue } = useGameSwap()

    return {
      fetchCurrentTimeline, swapGame, swapGameTimeAndVenue
    }
  },
  watch: {
    size: 'changeSize'
  },
  computed: {
    scheduleGameTitle () {
      return this.$t('schedule_game')
    }
  },
  methods: {
    scheduleGameModal (game) {
      if (!game.venue && this.venues.length === 1) {
        game.venue = this.venues[0]
      }

      this.scheduleGameModel = game

      if (!this.scheduleGameModel.time) {
        const d = new Date()
        const date = d.toISOString().split('T')[0]
        const time = d.toTimeString().split(' ')[0].split(':').slice(0, 2).join(':')

        this.scheduleGameModel.time = `${date} ${time}`
      }

      this.$modal.show('schedule-modal')
    },
    cancelScheduledGame (game) {
      GameService.cancelScheduledGame(this.$route.params.uuid, game.uuid).then((res) => {
        game.time = res.data.time
        game.venue = res.data.venue
        game.code = res.data.code
        game.status = res.data.status

        this.fetchCurrentTimeline()
      })
    },
    scheduleGameSubmit () {
      this.scheduleGameLoading = true
      GameService.scheduleGame(this.$route.params.uuid, this.scheduleGameModel.uuid, {
        time: this.scheduleGameModel.time,
        venue: this.scheduleGameModel.venue,
        info: this.scheduleGameModel.info
      }).then((res) => {
        this.fetchCurrentTimeline()
        this.scheduleGameModel.time = res.data.time
        this.scheduleGameModel.venue = res.data.venue
        this.scheduleGameModel.code = res.data.code
        this.scheduleGameModel.status = res.data.status
        this.$emit('changed', res.data)
        this.$modal.hide('schedule-modal')
      }).catch((err) => {
        this.scheduleGameForm.$errors = err.response.data.errors
      }).finally(() => {
        this.scheduleGameLoading = false
      })
    }
  }
}
</script>
<style>
 .qr-button {
   display: block;
   border: 0;
   background: none;
   cursor: pointer;
 }
</style>

<template>
  <div class="home">
    <page-content>
      <ntm-block>
        <template slot="options">
          <pdf-schedule-download></pdf-schedule-download>
          <export-scheduled-games-csv v-if="leagues?.length" :leagues="leagues"></export-scheduled-games-csv>
          <div v-if="timeline?.gamesCount > 0" class="btn-group ml-3">
            <button class="btn btn-sm " :class="{'btn-info': currentTimeline === 'all'}" @click="setCurrentTimeline('all')">{{ $t('all') }}</button>
            <button class="btn btn-sm" :class="{'btn-info': currentTimeline === 'published'}" @click="setCurrentTimeline('published')">{{ $t('published') }}</button>
            <button class="btn btn-sm" :class="{'btn-info': currentTimeline === 'unpublished'}" @click="setCurrentTimeline('unpublished')">{{ $t('unpublished') }}</button>
          </div>
          <button v-if="timeline?.gamesCount > 0" @click="publishTimeline()"
                  class="btn btn-sm btn-success ml-3">
            {{ $t('schedule.publish') }}
            <span v-if="timeline?.gamesCount" class="ml-1 badge badge-pill badge-light">
              {{ timeline?.gamesCount }}
            </span>
          </button>
        </template>
      </ntm-block>
      <div v-for="(date, n) in model.games" :key="`date-${n}`">
        <ntm-block>
          <h2>{{ $d(new Date(n.replace('T', ' ').replace(/-/g, '/')), 'dateLong') }}</h2>
          <div v-for="(venue, n) in date" :key="`venue-${n}`" class="venue-caption">
            <h3>{{ n }}</h3>
            <table class="table table-bordered table-striped table-vcenter">
              <thead>
              <tr>
                <th>Code</th>
                <th>Time</th>
                <th>League</th>
                <th class="text-center">Game</th>
                <th class="text-center">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="game in venue" :key="game.uuid" :class="gameStatusColor(game.status)">
                <td>
                  <template v-if="['SCHEDULED', 'FINISHED'].includes(game.status)">
                    <div class="btn-group">
                      <button class="btn btn-info btn-sm"
                              v-if="['SCHEDULED', 'FINISHED'].includes(game.status) && game.teamA && game.teamB"
                              @click="scheduleGameModal(game)"><i class="fa fa-clock-o"></i></button>
                      <button class="btn btn-warning btn-sm"
                              v-if="['SCHEDULED'].includes(game.status) && game.teamA && game.teamB"
                              @click="cancelScheduledGame(game)"><i class="fa fa-times"></i></button>
                    </div>
                  </template>
                  <template v-if="['ARCHIVED'].includes(game.status)">
                    <button @click="generateCode(game)" class="btn btn-sm btn-warning">
                      <i class="fa fa-undo"></i>
                    </button>
                  </template>
                  <span class="ml-2" :class="{'text-warning': newCode === game.code}" v-if="game.code">{{
                      game.code
                    }}</span>
                </td>
                <td>{{ $d(new Date(game.time.replace('T', ' ').replace(/-/g, '/')), 'time') }}</td>
                <td>{{ game.league.name }}</td>
                <td class="text-center"><strong>{{ game.teamA?.name ?? '?' }}</strong> :
                  <strong>{{ game.teamB?.name ?? '?' }}</strong></td>
                <td class="text-center">
                  <le-copy-to-clipboard v-if="!['ARCHIVED'].includes(game.status)" icon="fa fa-calculator" class="m-1"
                                        :text="getScorekeeperGameUrl(game.code)"></le-copy-to-clipboard>
                  <le-generate-game-news :game="game"></le-generate-game-news>
                  <le-copy-to-clipboard icon="fa fa-globe" class="m-1"
                                        :text="getGameWebsiteUrl(game)"></le-copy-to-clipboard>
                  <game-embed-video class="m-1" :game="game" @changed="fetchData"></game-embed-video>
                  <le-y-t-live class="m-1" :schedule="game.schedule" :game="game" @changed="fetchData"></le-y-t-live>
                  <button v-if="['NOT_PLAYED', 'SCHEDULED'].includes(game.status)" @click="swapGameTimeAndVenue(game)" :class="{'btn-success': swapGame?.uuid === game.uuid, 'btn-info': swapGame?.uuid !== game.uuid}" class="btn btn-sm m-1"><i class="fa fa-arrows-v"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </ntm-block>
      </div>
    </page-content>
    <modal name="schedule-modal" :adaptive="true" height="auto">
      <form @submit.prevent="scheduleGameSubmit()">
        <ntm-block :title="scheduleGameTitle">
          <formly-form :form="scheduleGameForm" :model="scheduleGameModel" :fields="scheduleGameFields"></formly-form>
          <button class="btn btn-success push-15" :disabled="scheduleGameLoading"><span
            v-if="!scheduleGameLoading">{{ $t('save') }}</span><i v-if="scheduleGameLoading"
                                                                  class="fa fa-cog fa-spin"></i></button>
        </ntm-block>
      </form>
    </modal>
  </div>
</template>

<script>
import DashboardService from '../services/dashboard.service'
import PdfScheduleDownload from '../components/shared/PdfScheduleDownload'
import VueI18n from '@/i18n'
import scheduleFormDefinition from '@/views/schedules/games/formDefinitions/scheduleFormDefinition'
import GameService from '@/services/game.service'
import { gameStatusColor } from '@/lib/status'
import { useExternalLinks } from '@/use/useExternalLinks'
import LeYTLive from '@/components/le/LeYTLive.vue'
import GameEmbedVideo from '@/components/le/GameEmbedVideo.vue'
import { useTimeline } from '@/use/useTimeline'
import { useRouteQuery } from '@/use/useRouteQuery'
import { ref } from 'vue'
import { useGameSwap } from '@/use/useGameSwap'
import ExportScheduledGamesCsv from '@/components/le/ExportScheduledGamesCsv.vue'

export default {
  components: {
    ExportScheduledGamesCsv,
    GameEmbedVideo,
    LeYTLive,
    PdfScheduleDownload
  },
  computed: {
    leagues () {
      return this.model.leagues ?? []
    },
    scheduleGameTitle () {
      const homeTeamName = this.scheduleGameModel?.teamA?.name
      const awayTeamName = this.scheduleGameModel?.teamB?.name
      return `${homeTeamName} --:-- ${awayTeamName}`
    }
  },
  data () {
    return {
      test: null,
      response: {
        id: 'zxmf-24D-d8',
        contentDetails: {
          monitorStream: {
            enableMonitorStream: true,
            broadcastStreamDelayMs: 0,
            test: 'width=560 height=315',
            embedHtml: '\u003ciframe width="425" height="344" src="https://www.youtube.com/embed/NmLtBdlagxw?autoplay=1&livemonitor=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen\u003e\u003c/iframe\u003e'
          }
        }
      },
      scheduleGameLoading: false,
      scheduleGameModel: {},
      scheduleGameForm: {},
      scheduleGameFields: scheduleFormDefinition,
      newCode: null
    }
  },
  beforeRouteEnter (to, from, next) {
    DashboardService.index().then((response) => {
      next(vm => {
        vm.setData(response.data)
      })
    })
  },
  mounted () {
    this.fetchData()
    // this.loginToGoogleApi()
  },
  setup () {
    const currentTimeline = useRouteQuery('timeline', 'all')
    const model = ref({})
    function setData (data) {
      model.value = data
    }
    function fetchData () {
      DashboardService.index(currentTimeline.value).then((res) => {
        setData(res.data)
      })
    }

    const { swapGame, swapGameTimeAndVenue } = useGameSwap(() => fetchData())

    const { timeline, fetchCurrentTimeline, publishTimeline } = useTimeline(() => {
      currentTimeline.value = 'all'

      setTimeout(() => {
        fetchData()
      })
    })
    const { getScorekeeperGameUrl, getGameWebsiteUrl, getGameBroadcastWebsiteUrl } = useExternalLinks()

    return {
      model,
      swapGame,
      swapGameTimeAndVenue,
      setData,
      fetchData,
      getGameBroadcastWebsiteUrl,
      getScorekeeperGameUrl,
      getGameWebsiteUrl,
      timeline,
      fetchCurrentTimeline,
      publishTimeline,
      currentTimeline
    }
  },
  methods: {
    setCurrentTimeline (timelineType) {
      this.currentTimeline = timelineType

      setTimeout(() => {
        this.fetchData()
      })
    },
    gameStatusColor,
    scheduleGameModal (game) {
      this.scheduleGameModel = game
      this.$modal.show('schedule-modal')
    },
    cancelScheduledGame (game) {
      GameService.cancelScheduledGame(game.schedule.uuid, game.uuid).then(() => {
        this.fetchData()
        this.fetchCurrentTimeline()
      })
    },
    scheduleGameSubmit () {
      this.scheduleGameLoading = true
      GameService.scheduleGame(this.scheduleGameModel.schedule.uuid, this.scheduleGameModel.uuid, {
        time: this.scheduleGameModel.time,
        venue: this.scheduleGameModel.venue,
        info: this.scheduleGameModel.info
      }).then((res) => {
        // this.scheduleGameModel.time = res.data.time
        // this.scheduleGameModel.venue = res.data.venue
        // this.scheduleGameModel.code = res.data.code
        // this.scheduleGameModel.status = res.data.status
        this.$modal.hide('schedule-modal')
        this.fetchData()
        this.fetchCurrentTimeline()
      }).catch((err) => {
        this.scheduleGameForm.$errors = err.response.data.errors
      }).finally(() => {
        this.scheduleGameLoading = false
      })
    },

    generateCode (game) {
      this.$swal({
        title: VueI18n.t('swal.generateCode.title'),
        text: VueI18n.t('swal.generateCode.text'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: VueI18n.t('swal.generateCode.yes'),
        cancelButtonText: VueI18n.t('swal.generateCode.no'),
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (!result.value) return

        DashboardService.generateCode(game.uuid).then((res) => {
          game.code = res.data.code
          game.status = res.data.status

          this.newCode = res.data.code

          this.$copyText(res.data.code).then(() => {
            // this.$notify('Copied to clipboard', 'success')
            this.$toast.fire({
              title: 'Copied to clipboard',
              icon: 'success'
            })
          })

          setTimeout(() => {
            this.newCode = null
          }, 10000)
        })
      })
    },
    downloadPDF () {
      return process.env.VUE_APP_BASE_URL + 'export/' + process.env.VUE_APP_CLIENT_UUID + '/schedule/'
    }
  }
}
</script>

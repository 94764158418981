<template>
  <nav id="sidebar" aria-label="Main Navigation">
    <!-- Side Header -->
    <div class="content-header bg-white-5">
      <!-- Logo -->

        <multiselect
          v-if="clients.length > 1"
                     style="max-width: 300px"
                     :allow-empty="false"
                     track-by="uuid"
                     class="organisation-select flex-grow-1"
                     :custom-label="(option) => option.name"
                     :placeholder="$t('search')"
                     v-model="currentClient"
                     :options="clients">
        </multiselect>
      <router-link v-if="clients.length === 1" class="font-w600 text-dual" :to="{name:'dashboard'}">
         {{ currentClient.name }}
      </router-link>
      <!-- END Logo -->

      <!-- Options -->
      <div>
        <!-- Close Sidebar, Visible only on mobile screens -->
        <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
        <a class="d-lg-none text-dual ml-3" @click="closeNav()" href="javascript:void(0)">
          <i class="fa fa-times"></i>
        </a>
        <!-- END Close Sidebar -->
      </div>
      <!-- END Options -->
    </div>
    <!-- END Side Header -->

    <!-- Side Navigation -->
    <div class="content-side content-side-full">
      <ul class="nav-main">
        <li class="nav-main-item">
          <router-link class="nav-main-link" :to="{name:'dashboard'}">
            <i class="nav-main-link-icon si si-cursor"></i>
            <span class="nav-main-link-name">{{ $t('navbar.dashboard') }}</span>
          </router-link>
        </li>
        <li v-if="mainLinks.length" class="nav-main-heading">{{ $t('navbar.main') }}</li>
        <li v-for="(link, n) in mainLinks" :key="link.route.name + n" class="nav-main-item">
          <router-link class="nav-main-link" :to="link.route">
            <i v-if="link.icon" :class="link.icon" class="nav-main-link-icon"></i>
            <span class="nav-main-link-name">{{ $tc(link.title, 2) }}</span>
          </router-link>
          <a v-if="link.iconFunction" href="javascript:void(0)" @click="link.iconFunction" class="icon-link"><i class="fa fa-plus-circle"></i>
          </a>
        </li>
        <!--<li class="nav-main-item">-->
        <!--<a class="nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="true" href="#">-->
        <!--<i class="nav-main-link-icon si si-bulb"></i>-->
        <!--<span class="nav-main-link-name">Examples</span>-->
        <!--</a>-->
        <!--<ul class="nav-main-submenu">-->
        <!--</ul>-->
        <!--</li>-->
        <li v-if="currentSeasonLinks.length" class="nav-main-heading">{{ $t('navbar.current_season') }}</li>
        <li v-for="(link, n) in currentSeasonLinks" :key="link.route.name + n" class="nav-main-item">
          <router-link class="nav-main-link" :to="link.route">
            <i v-if="link.icon" :class="link.icon" class="nav-main-link-icon"></i>
            <span class="nav-main-link-name">{{ $tc(link.title, 2) }}</span>
          </router-link>
        </li>
        <li v-if="settingsLinks.length" class="nav-main-heading">{{ $t('navbar.settings') }}</li>
        <li v-for="(link, n) in settingsLinks" :key="link.route.name + n" class="nav-main-item">
          <router-link class="nav-main-link" :to="link.route">
            <i v-if="link.icon" :class="link.icon" class="nav-main-link-icon"></i>
            <span class="nav-main-link-name">{{ $tc(link.title, 2) }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- END Side Navigation -->
  </nav>
</template>

<script>
import router from '@/router'
import { useCreateNewEntity } from '@/use/useCreateNewEntity'
import Multiselect from 'vue-multiselect'
import { useGameSwap } from '@/use/useGameSwap'

export default {
  components: { Multiselect },
  setup () {
    const { createEmptySponsorAndEdit, createEmptyNewsItemAndEdit } = useCreateNewEntity()
    const { swapGame } = useGameSwap()
    return { createEmptySponsorAndEdit, createEmptyNewsItemAndEdit, swapGame }
  },
  computed: {
    mainLinks () {
      if (!this.$store.getters.isAdmin) {
        return []
      }

      return [
        {
          title: 'player.name',
          icon: 'fa fa-child',
          route: { name: 'players.index', params: { clientId: this.currentClient.uuid } },
          iconFunction: () => this.goToRoute({ name: 'players.create', params: { clientId: this.currentClient.uuid } })
        },
        {
          title: 'coach.name',
          icon: 'fa  fa-user-circle',
          route: { name: 'coaches.index', params: { clientId: this.currentClient.uuid } },
          iconFunction: () => this.goToRoute({ name: 'coaches.create', params: { clientId: this.currentClient.uuid } })
        },
        {
          title: 'club.name',
          icon: 'fa fa-map-signs',
          route: { name: 'clubs.index', params: { clientId: this.currentClient.uuid } },
          iconFunction: () => this.goToRoute({ name: 'clubs.create', params: { clientId: this.currentClient.uuid } })
        },
        {
          title: 'league.name',
          icon: 'fa fa-table',
          route: { name: 'leagues.index', params: { clientId: this.currentClient.uuid } },
          iconFunction: () => this.goToRoute({ name: 'leagues.create', params: { clientId: this.currentClient.uuid } })
        },
        {
          title: 'season.name',
          icon: 'fa fa-trophy',
          route: { name: 'seasons.index', params: { clientId: this.currentClient.uuid } },
          iconFunction: () => this.goToRoute({ name: 'seasons.create', params: { clientId: this.currentClient.uuid } })
        },
        {
          title: 'venue.name',
          icon: 'fa fa-map-marker',
          route: { name: 'venues.index', params: { clientId: this.currentClient.uuid } },
          iconFunction: () => this.goToRoute({ name: 'venues.create', params: { clientId: this.currentClient.uuid } })
        },
        {
          title: 'news.name',
          icon: 'fa fa-newspaper-o',
          route: { name: 'news.index', params: { clientId: this.currentClient.uuid } },
          iconFunction: () => this.createEmptyNewsItemAndEdit()
        },
        {
          title: 'imageRequest.name',
          icon: 'si si-picture',
          route: { name: 'imageRequests.index', params: { clientId: this.currentClient.uuid } }
        },
        {
          title: 'sponsor.name',
          icon: 'fa fa-handshake-o',
          route: { name: 'sponsors.index', params: { clientId: this.currentClient.uuid } },
          iconFunction: () => this.createEmptySponsorAndEdit()
        },
        {
          title: 'document.name',
          icon: 'fa fa-handshake-o',
          route: { name: 'documents.index', params: { clientId: this.currentClient.uuid } },
          iconFunction: () => this.goToRoute({ name: 'documents.create', params: { clientId: this.currentClient.uuid } })
        }
      ]
    },
    currentSeasonLinks () {
      return [
        {
          title: 'schedule.name',
          icon: 'fa fa-calendar',
          route: { name: 'schedules.index', params: { clientId: this.currentClient.uuid } }
        }
      ]
    },
    settingsLinks () {
      if (!this.$store.getters.isAdmin) {
        return []
      }

      return [
        {
          title: 'settings.name',
          icon: 'fa fa-cog',
          route: { name: 'settings.index', params: { clientId: this.currentClient.uuid } }
        },
        {
          title: 'connectedAccount.name',
          icon: 'fa fa-cog',
          route: { name: 'connected-account.index', params: { clientId: this.currentClient.uuid } }
        },
        {
          title: 'import.name',
          icon: 'fa fa-upload',
          route: { name: 'import.index' }
        }
      ]
    },
    currentClient: {
      get () {
        return this.$store.state.currentClient
      },
      async set (value) {
        await this.$store.dispatch('setClient', value)
        this.swapGame = null
        console.log('currentClient', this.currentClient.uuid)
        this.$router.push('/')
      }
    },
    clients () {
      return this.$store.state.user.clients
    }
  },
  methods: {
    goToRoute (route) {
      router.push(route)
    },
    closeNav () {
      this.$store.dispatch('closeNav')
    }
  }
}
</script>

import Index from '@/views/settings/Index'
import ImportIndex from '@/views/import/Index'
import ConnectedAccountsIndex from '@/views/connectedAccounts/Index'

export default [
  {
    path: ':clientId/settings/website',
    name: 'settings.index',
    component: Index
  },
  {
    path: ':clientId/settings/import',
    name: 'import.index',
    component: ImportIndex
  },
  {
    path: ':clientId/settings/connected-accounts',
    name: 'connected-account.index',
    component: ConnectedAccountsIndex
  }
]
